const deliveryCheckoutModule = {
    state:()=>({
        deliveryInfo:null,
    }),
    mutations:{
        setDeliveryInfo(state,payload){
            state.deliveryInfo = payload;
            console.log(state.deliveryInfo);
        }
    },
    actions:{
        setDeliveryInfo({commit}, payload) {
            commit('setDeliveryInfo',payload)
        }
    },
    getters:{
        receiverDeliveryInfo(state){
            return state.deliveryInfo
        }
    }
}
export default deliveryCheckoutModule