import Vue from 'vue'
import Vuex from 'vuex'
import contact from '@/store/modules/contactUs';
import shopModule  from "./modules/shop";
import basketModule from "./modules/basket";
import deliveryCheckoutModule from "./modules/deliveryCheckout";
import personalCheckoutModule from "./modules/personalCheckout";
import authModule from "./modules/auth";
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    contact,
    shopModule,
    basketModule,
    deliveryCheckoutModule,
    personalCheckoutModule,
    authModule
  }
})