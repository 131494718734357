<template>
	<div id="app">
		<router-view />
	</div>
</template>
<script>
export default {
	name: "App"
};
</script>
<style lang="scss">
@import "scss/default";
@import "scss/d-flex.scss";
@import "scss/transitions.scss";
@import "scss/animations.scss";
@import "views/styles.scss";
@import "components/styles.scss";
</style>
