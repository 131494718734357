import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [{
    path: '/',
    name: 'MainPages',
    component: () => import('../layout/MainPages/MainPages'),
    children: [
        {
        name: 'Home',
        path: '/',
        component: () => import('../views/Home/Home.vue'),
        },
        {
            path: '/terms-conditions',
            name: 'TermsConditions',
            component: () => import('../views/TermsConditions'),

        },
        {
            path: '/successful-payment',
            name: 'ThankYou',
            component: () => import('../views/ThankYou'),
        },
        {
            path: '/failed-payment',
            name: 'ThankYou',
            component: () => import('../views/FailedPayment'),

        },
        {
            path: '/contact-us',
            name: 'ContactUs',
            component: () => import('../views/ContactUs'),
        },
        {
            name:'/shop',
            path:'/shop',
            component:() => import('../views/Shop/Shop'),
        },
        {
            name:'selectProduct',
            path:'/selectProduct/&:productId',
            component:() => import('../views/SelectProduct/SelectProduct'),
        },
        {
            name:'gifts',
            path:'/selectProduct/&:productId/gifts',
            component:()=> import('../views/Gifts/Gifts')
        },
        {
            name:'cards',
            path:'/selectProduct/&:productId/cards',
            component:()=> import('../views/PresentCards/PresentCards')
        },
        {
            name:'selectGift',
            path:'/selectProduct/&:productId/selectGift/&:giftId',
            component:()=>import('../views/SelectGift/SelectGift'),
        },
        {
            name:'selectPresentCards',
            path:'/selectProduct/&:productId/selectPresentCards/&:cardId',
            component:()=>import('../views/SelectPresentCards/SelectPresentCards'),
        }
    ]
    },
    {
        path: '/SecondaryPages',
        name: 'SecondaryPages',
        component:()=> import('../layout/SecondaryPages/SecondaryPages'),
        children: [
            {
                path:'/checkout',
                name:'checkout',
                component:()=> import('../views/Checkout/Checkout')
            },
            {
                path:'/checkoutPersonalInfo',
                name:'checkoutPersonalInfo',
                component:()=> import('../views/Checkout/CheckoutPersonalInfo/CheckoutPersonalInfo')
            },
            {
                path: '/checkoutPayment',
                name:'checkoutPayment',
                component:()=> import('../views/Checkout/CheckoutPayment/CheckoutPayment')
            },
        ]
    },
    {
        path:'/TherdPages',
        name:'/TherdPages',
        component:()=>import('../layout/TherdPages/TherdPages'),
        children:[
            {
                path: '/signIn',
                name:'signIn',
                component:()=> import('../views/SignIn/SignIn')
            },
            {
                path:'/signUp',
                name:'signUp',
                component:()=> import('../views/SignIn/SignUp/SignUp')
            },
            {
                path:'/signUpSecondStep',
                name:'signUpSecondStep',
                component:()=> import('../views/SignIn/SignUp/SignUpSecondStep')
            }
        ]
    }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router