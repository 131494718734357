import service from './service';

export function emailExists(email = '', config = {}) {
    const body = {}
    config = {
        params: {
            value: email
        }
    }
    return service.post('/email-exists', body, config)
}

export function login(body = {}, config = {}) {
    return service.post('/login', {}, config)
}

export function loginEmail(body = {}, config = {}) {
    config = {
        params: body
    }
    return service.post('/login/email', {}, config)
}

export function sendPasswordResetKey(email, config = {}) {
    config = {
        params: {
            email
        }
    }
    return service.post('/email/send-password-reset-key', {}, config).then(response => response).catch(e => console.error(e))
}

export function verifyPasswordResetKey(body = {}, config = {}) {
    config = {
        params: {
            ...body
        }
    }
    return service.post('/email/verify-password-reset-key', {}, config)
}

export function sendUpdateVerificationKey(email,config = {}){
    config = {
        params : {
            newEmail : email
        }
    }
    return  service.post('request-update-email-verification-key',{}, config)
}

export function enterEmailVerificationKey(body,config){
    config = {
        params : {
            ...body
        }
    }
    return  service.post('verify-update-email-verification-key',{}, config)
}

export function resetPassword(body = {}, config = {}) {
    config = {
        params: {
            ...body
        }
    }
    return service.post('/email/reset-password', {}, config)
}

export function changePassword(body = {}) {
    return service.post('user-profile/change-password', body)
}

export function requestRegEmailVerificationKey(body = {}, config = {}) {
    config = {
        params: {
            ...body
        }
    }
    return service.post('/request-reg-email-verification-key', {}, config)
}

export function verifyRegEmailVerificationKey(body = {}, config = {}) {
    config = {
        params: {
            ...body
        }
    }
    return service.post('/verify-reg-email-verification-key', {}, config)
}

export function registration(body = {}, config = {}) {
    config = {
        params: config
    }
    return service.post('/registration', body, config)
}

export function subscribe(body = {}, config = {}) {
    return service.post('/subscription', body, config)
}

export function getProducts(body = {}, config = {}) {
    config = {
        params: {
            ...body
        }
    }
    return service.get('product/', config)
}

export function getWeeklyBouquetSelection(body = {}, config = {}) {
    config = {
        params: {
            ...body
        }
    }
    return service.get('/weekly-bouquet-selection/current', config)
}

export default {
    emailExists,
    login,
    loginEmail,
    changePassword,
    sendPasswordResetKey,
    verifyPasswordResetKey,
    resetPassword,
    requestRegEmailVerificationKey,
    sendUpdateVerificationKey,
    enterEmailVerificationKey,
    verifyRegEmailVerificationKey,
    registration,
    subscribe,
    getProducts,
    getWeeklyBouquetSelection
}
