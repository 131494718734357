const personalCheckoutModule = {
    state:()=>({
        personalInfo:null,
    }),
    mutations:{
        setPersonalInfo(state,payload){
            state.personalInfo = payload;
            console.log(state.personalInfo);
        }
    },
    actions:{
        setPersonalInfo({commit}, payload) {
            commit('setPersonalInfo',payload)
        }
    },
    getters:{
        receiverPersonalInfo(state){
            return state.personalInfo
        }
    }
}
export default personalCheckoutModule