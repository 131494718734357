const basketModule = {
    state:()=>({
        basket:[],
        blank:null,
        selectCustomId:null,
    }),
    mutations:{
        setBasket(state,payload){
            state.basket.push(payload);
        },
        setBlankTav(state,payload){
            state.blank = {...state.blank,tav:payload};
            if(state.basket.length === 0){
                state.basket.push({...state.blank,customId:Date.now()})
            }else{
                if(state.basket.find(item => item.customId === payload.productId)){
                    state.basket = state.basket.map((el) => {
                        if(el.customId === payload.productId){
                            return {...el,tav:{fname:payload.fname,lname:payload.lname}}
                        }
                        return el
                    });
                }else{
                    state.basket.push({...state.blank,customId:Date.now()})
                }
            }
        },
        setBlankCard(state,payload){
            state.blank = {...state.blank,card:payload};
            if(state.basket.length === 0){
                state.basket.push({...state.blank,customId:Date.now()})
            }
            else{
                if(state.basket.find(item => item.customId === state.selectCustomId)){
                    state.basket = state.basket.map((el) => {
                        if(el.customId === state.selectCustomId){
                            return {...el,card:payload}
                        }
                        return el
                    });
                }else{
                    state.basket.push({...state.blank,customId:Date.now()})
                }
            }
        },
        setBlankGift(state,payload){
            state.blank = {...state.blank,gift:payload};
            if(state.basket.length === 0){
                state.basket.push({...state.blank,customId:Date.now()})
            }
            else{
                if(state.basket.find(item => item.customId === state.selectCustomId)){
                    state.basket = state.basket.map((el) => {
                        if(el.customId === state.selectCustomId){
                            return {...el,gift:payload}
                        }
                        return el
                    });
                }else{
                    state.basket.push({...state.blank,customId:Date.now()})
                }
            }
        },
        setBlank(state,payload){
            state.blank = payload
        },
        deleteItem(state,payload){
            state.basket = state.basket.filter(el => el.customId !== payload)
        },
        setSelectCustomId(state,payload){
            state.selectCustomId = payload;
        },
        deleteTav(state,payload){
            state.basket = state.basket.map(el => el.customId === payload ? {...el,tav:null} : el)
        },
        deletePresentCard(state,payload) {
            state.basket = state.basket.map(el => el.customId === payload ? {...el,card:null} : el)
        },
        deleteGift(state,payload) {
            state.basket = state.basket.map(el => el.customId === payload ? {...el,gift:null} : el)
        }
    },
    actions:{
        addToBasket(context,payload){
            context.commit('setBasket',payload)
        },
        changeProductDataTav(context,payload){
            context.commit('setBlankTav',payload)
        },
        changeProductDataCard(context,payload){
            context.commit('setBlankCard',payload)
        },
        changeProductDataGift(context,payload) {
            context.commit('setBlankGift', payload)
        },
        setBlank(context,payload){
            context.commit('setBlank',payload)
        },
        deleteItem(context,payload){
            context.commit('deleteItem',payload)
        },
        addSelectCustomId({commit},payload){
            commit('setSelectCustomId',payload)
        },
        deleteTav({commit},payload){
            commit('deleteTav',payload)
        },
        deletePresentCard({commit},payload){
            commit('deletePresentCard',payload)
        },
        deleteGift({commit},payload){
            commit('deleteGift',payload)
        },
    },
    getters:{
        receiveBasket(state){
            return state.basket;
        }
    }
}

export default basketModule