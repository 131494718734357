import axios from "axios";
import { asyncLoading, } from "vuejs-loading-plugin";
const api = `https://demo-api.newtribe.nl:4431/api/`;
//const api = `https://demo-flowers-api.newtribe.nl:4431/api/`;

const shopModule = {
    state: () => ({
        products: [],
        category: [],
        minimalAndMaximumPrice: null,
        selectProduct: null,
        gifts: null,
        selectGift: null,
    }),
    mutations: {
        setStateProducts(state, payload) {
            state.products = payload;
        },
        setCategory(state, payload) {
            state.category = payload;
        },
        setPrice(state, payload) {
            state.minimalAndMaximumPrice = payload
        },
        setSelectProduct(state, payload) {
            state.selectProduct = payload;
        },
        setGifts(state, payload) {
            state.gifts = payload
        },
        setSelectGift(state, payload) {
            state.selectGift = payload
        },
    },
    actions: {
        async getProducts({ commit, dispatch }, payload = 0) {
            commit("setStateProducts", []);
            const products = await axios({
                url: api + `product/?size=11&page=${payload}`,
                method: 'GET',
                headers: {
                    tenantId: 0,
                },
            })
            const product = products.data;
            const pages = products.headers["total-pages"];
            commit("setStateProducts", { product, pages });
        },
        async getCategory(context) {
            const products = await axios({
                url: api + `category/with-count?withProductsOnly=false`,
                method: 'GET',
                headers: {
                    tenantId: 0,
                },
            })
            context.commit("setCategory", products.data);
        },
        async getFilterProduct(context, payload) {
            const products = await axios({
                url: api + `filter?size=11&page=${payload.page}&sort=orderCounts,desc`,
                method: 'POST',
                headers: {
                    tenantId: 0,
                },
                data: {
                    "categories": payload.category,
                    "maxPrice": payload.price[1] * 100,
                    "minPrice": payload.price[0] * 100
                }
            })
            const product = products.data;
            const pages = products.headers["total-pages"];
            context.commit("setStateProducts", { product, pages });
        },
        async getCategoryProduct(context, payload) {
            const products = await axios({
                url: api + `product/by-category/${payload.id}?size=11&page=${payload.page}`,
                method: 'GET',
                headers: {
                    tenantId: 0,
                },
            })
            const product = products.data;
            const pages = products.headers["total-pages"];
            context.commit("setStateProducts", { product, pages });
        },
        async getAllPrice(context) {
            const products = await axios({
                url: api + `product/product-price-info/`,
                method: 'GET',
                headers: {
                    tenantId: 0,
                },
            })
            const values = await products.data.sort((a, b) => a.price - b.price);
            context.commit('setPrice', { min: values[0].price / 100, max: values[values.length - 1].price / 100 })
        },
        async getSearchProduct(context, payload) {
            const products = await axios({
                url: api + `product/search-by-keyword/${payload.inputData}?size=9&page=${payload.page}`,
                method: 'GET',
                headers: {
                    tenantId: 0,
                },
            })
            context.commit("setStateProducts", { product: products.data, pages: 0 });
        },
        async getSelectProduct({ commit, dispatch }, payload) {
            commit('setSelectProduct', null)
            const product = await axios({
                url: api + `product/${payload}`,
                method: 'GET',
                headers: {
                    tenantId: 0,
                },
            });
            const changeResponse = { ...product.data, counter: 1, tav: null, card: null, gift: null };
            commit('setSelectProduct', changeResponse)
            dispatch('setBlank', changeResponse)
        },
        async getGiftsProducts(context) {
            const products = await axios({
                url: api + `gift-card/`,
                method: 'GET',
                headers: {
                    tenantId: 0,
                },
            })
            context.commit('setGifts', products.data)
        },
        async getSelectGift(context, payload) {
            const products = await axios({
                url: api + `gift-card/${payload}`,
                method: 'GET',
                headers: {
                    tenantId: 0,
                },
            });
            context.commit('setSelectGift', { ...products.data, counter: 1 })
        },
    },
    getters: {
        receiveProduct(state) {
            return state.products;
        },
        receiveCategory(state) {
            return state.category;
        },
        receivePrice(state) {
            return state.minimalAndMaximumPrice
        },
        receiveSelectProduct(state) {
            return state.selectProduct;
        },
        receiveGifts(state) {
            return state.gifts;
        },
        receiveSelectGift(state) {
            return state.selectGift;
        },
    },
}


export default shopModule