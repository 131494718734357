import contactApi from '@/api/contact-us'


const state = {
	isSended: false,
	error: null
}

export const mutationTypes = {
	sendContactStart: '[contacts] Send contact Start',
	sendContactSuccess: '[contacts] Send contact Success',
	sendContactFailure: '[contacts] Send contact Failure'
}


export const actionTypes = {
	sendContact: '[contacts] Send contact'
}

const mutations = {
	[mutationTypes.sendContactStart](state) {
		state.isSended = false;
	},
	[mutationTypes.sendContactSuccess](state) {
		state.isSended = true;
	},
	[mutationTypes.sendContactFailure](state, payload) {
		state.isSended = false;
		state.error = payload;
	}
}

const actions = {
	[actionTypes.sendContact](context, payload) {
		return new Promise(resolve => {
			context.commit(mutationTypes.sendContactStart)
			contactApi
				.sendContact(payload)
				.then(response => {
					context.commit(mutationTypes.sendContactSuccess, response.data)
					resolve(response.data)
				})
				.catch((e) => {
					context.commit(mutationTypes.sendContactFailure, e)
				})
		})
	}
}

export default {
	state,
	mutations,
	actions
}