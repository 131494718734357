import axios from 'axios';
import Cookie from "js-cookie";

const service = axios.create({
    baseURL: 'https://demo-api.newtribe.nl:4431/api',
    timeout: 15000,
    // headers: {
    //     'Authorization': await window.cookieStore.get('token')?.value
    // }
    withCredentials: false,
    headers: {
        // "Access-Control-Allow-Origin": "*",
        'Authorization': `Bearer ${Cookie.get('token')}`,
        "tenantId": 0
    }
})

export default service