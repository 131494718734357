import ApiAuth from '/src/api/autorization'
import Cookie from 'js-cookie';

const authModule =  {

    state:() => ({
        user: [],
    }),

    getters: {
        user: state => state.user,
    },

    mutations: {
        GET_USER_INFO(state, user) {
            state.user = user
        },
    },

    actions: {
        changePassword({}, params) {
            return ApiAuth.changePassword(params)

        },
        // Request to check verify code
        resetPassword({}, params) {
            return ApiAuth.resetPassword(params).then((response) => {
                console.log(response.data)
                return response.data
            }).catch((error) => {
                console.log(error)
            })
        },
        // Request to check verify code
        checkVerificationKey({}, params) {
            return ApiAuth.checkVerificationKey(params).then((response) => {
                console.log(response.data)
                return response.data
            }).catch((error) => {
                if (error.response) {
                    Notification.error({
                        title: i18n.t('notification.error_title'),
                        message: error.response.data.message
                    });
                }
            })
        },
        // Request to check if user exist
        checkExistUserByEmail({}, params) {
            return ApiAuth.checkExistUserByEmail(params).then((response) => {
                console.log(response.data)
                return response.data
            }).catch((error) => {
                if (error.response) {
                    Notification.error({
                        title: i18n.t('notification.error_title'),
                        message: error.response.data.message
                    });
                }
            })
        },
        getVerificationCode({}, params) {
            return ApiAuth.getVerificationCode(params).then((response) => {
                console.log(response.data)
                return response.data
            }).catch((error) => {
                if (error.response) {
                    Notification.error({
                        title: i18n.t('notification.error_title'),
                        message: error.response.data.message
                    });
                }
            })
        },
        loginByEmail({}, params) {
            return ApiAuth.loginEmail({
                password: params.password,
                value: params.login
            }).then((response) => {
                if (response.status == 200) {
                    Cookie.set('token', response.data.data)
                    console.log('ok')
                    console.log(response.data)
                }
                Notification.success({
                    title: i18n.t('notification.login.success.title'),
                    message: i18n.t('notification.login.success.message'),
                    type: 'success'
                });

                return response.data
            }).catch((error) => {
                if (error.response) {
                    Notification.error({
                        title: i18n.t('notification.error_title'),
                        message: error.response.data.message
                    });
                    return error.response
                }
            })
        },
        sendUpdateVerificationKey({}, params){
            return  ApiAuth.sendUpdateVerificationKey(params)
        },
        enterEmailVerificationKey({},params){
            return  ApiAuth.enterEmailVerificationKey(params)
        },
        sendPasswordResetKey({},email){
            return ApiAuth.sendPasswordResetKey(email).then( response => response ).catch(e => console.error(e))
        },
        verifyPasswordResetKey({},payload){
            return ApiAuth.verifyPasswordResetKey({
                email: payload.email,
                key: payload.code
            })
            .then(res => {
                console.log(res)
                if (res.data.data) {
                    let token = res.data.data
                    return token
                } else {
                    // setFieldError('verificationCode', t('account.verification_code_is_not_correct'))
                }
            })
            .catch(error => console.error(error))
        },

    }
}
export default authModule