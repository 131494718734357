import axios from 'axios'

const sendContact = (payload) => {
	return axios.post('https://demo-api.newtribe.nl:4431/api/contact-us', payload, {
		headers: {
			tenantId: 0,
		}
	});
}

export default {
	sendContact
}