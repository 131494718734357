import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueCompositionAPI from '@vue/composition-api';
import VueLoading from 'vuejs-loading-plugin';

Vue.config.productionTip = false;
Vue.use(VueCompositionAPI);
Vue.use(VueLoading,{
  dark:true,
  loading:false,
  classes:['my-loader'],
  background:'#252526',
})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')